import React from 'react';

import iconType, { ICON_TYPE } from '../../types/iconTypes';

import './Icon.scss';

class Icon extends React.PureComponent {
  static propTypes = iconType;

  render() {
    const { type, ...props } = this.props;
    if (type === ICON_TYPE.rightArrow) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22" width="24" height="22" {...props}>
          <path d="M23.5 10.8c0-.3-.1-.5-.2-.7 0-.1-.1-.2-.1-.2l-7.3-8.7c-.5-.5-1.3-.6-1.8-.2-.5.5-.6 1.3-.2 1.8l5.7 6.8H1.3c-.7-.1-1.3.5-1.3 1.2S.6 12 1.3 12h18.2l-5.7 6.8c-.5.5-.4 1.3.2 1.8.5.5 1.3.4 1.8-.2l7.3-8.7c.1-.1.1-.2.1-.2.2-.2.3-.5.3-.7z" fillRule="evenodd" clipRule="evenodd" />
        </svg>
      );
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50" {...props}>
        {type === ICON_TYPE.facebook && (
          <React.Fragment>
            <path d="M26 18.3v-.2c0-1.1.2-1.8 1.8-1.8h2.7l.1-3.9s-1.3-.2-3-.2c-4.3 0-6.1 2.6-6.1 5.3V21h-3.1v4.1h3.1v11.5H26V25.2h4.4l.2-4.1H26v-2.8z" />
            <path d="M25 .8C11.6.8.8 11.6.8 25S11.6 49.2 25 49.2c13.4 0 24.2-10.9 24.2-24.2S38.4.8 25 .8zm0 45.9C13 46.7 3.2 37 3.2 25S13 3.3 25 3.3 46.8 13 46.8 25 37 46.7 25 46.7z" />
          </React.Fragment>
        )}
        {type === ICON_TYPE.instagram && (
          <React.Fragment>
            <circle cx="32.5" cy="17.5" r="1.5" />
            <path d="M25 18c-3.9 0-7 3.1-7 7s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7zm0 12c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" />
            <path d="M30.5 12h-11c-4.2 0-7.5 3.4-7.5 7.5v10.9c0 4.2 3.4 7.5 7.5 7.5h10.9c4.2 0 7.5-3.4 7.5-7.5V19.5c.1-4.1-3.3-7.5-7.4-7.5zM36 30.5c0 3.1-2.5 5.5-5.5 5.5h-11c-3.1 0-5.5-2.5-5.5-5.5v-11c0-3.1 2.5-5.5 5.5-5.5h10.9c3.1 0 5.5 2.5 5.5 5.5v11z" />
            <path d="M25 .8C11.6.8.8 11.6.8 25S11.6 49.2 25 49.2c13.4 0 24.2-10.9 24.2-24.2S38.4.8 25 .8zm0 45.9C13 46.7 3.2 37 3.2 25S13 3.3 25 3.3 46.8 13 46.8 25 37 46.7 25 46.7z" />
          </React.Fragment>
        )}
        {type === ICON_TYPE.twitter && (
          <React.Fragment>
            <path d="M37 15.6c-1 .6-2 1-3.2 1.2-1-1-2.3-1.5-3.7-1.5-2.8 0-5 2.2-5 4.9 0 .4 0 .8.1 1.1-4-.2-7.7-2-10.3-5.1-.4.7-.7 1.6-.7 2.5 0 1.7.9 3.2 2.2 4.1-.8 0-1.6-.2-2.3-.6v.1c0 2.4 1.7 4.3 4 4.8-.7.2-1.5.2-2.3.1.6 1.9 2.5 3.4 4.7 3.4-1.8 1.4-4 2.1-6.2 2.1-.4 0-.8 0-1.2-.1 2.3 1.4 5 2.2 7.7 2.2C30 34.8 35 27.3 35 20.9v-.6c1-.7 1.8-1.5 2.5-2.5-.9.4-1.9.7-2.9.8 1.2-.9 2-1.8 2.4-3z" />
            <path d="M25 .8C11.6.8.8 11.6.8 25S11.6 49.2 25 49.2c13.4 0 24.2-10.9 24.2-24.2S38.4.8 25 .8zm0 45.9C13 46.7 3.2 37 3.2 25S13 3.3 25 3.3 46.8 13 46.8 25 37 46.7 25 46.7z" />
          </React.Fragment>
        )}
      </svg>
    );
  }
}

export default Icon;
